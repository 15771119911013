import JotFormEmbed from "components/JotFormEmbed"
import { getStoryblokLink } from "utilities/getStoryblokLink"
import richText from "utilities/richText"

export default function BookForm({ jotform_url, form_content }) {
  return (
    <section className="px-5 bg-highlight-tint py-16 overflow-hidden">
      <div className="max-w-5xl mx-auto">
        <div className="max-w-4xl mx-auto text-center prose-headings:pb-4 prose-h2:text-primary pb-8">
          {richText(form_content)}
        </div>
        <div className="book-form form-brackets form-brackets-highlight-tint relative">
          <JotFormEmbed formURL={getStoryblokLink(jotform_url)} initialHeight={650} />
        </div>
      </div>
    </section>
  )
}
