import Close from "public/assets/close-modal.svg"

export default function Modal({ children, isOpen, closeModal, focusRef }) {
  return isOpen ? (
    <>
      <div
        onClick={() => closeModal()}
        className="overlay fixed w-full h-full top-0 left-0 right-0 bottom-0 z-[9998] bg-black bg-opacity-50"
      />
      <div
        tabIndex={0}
        ref={focusRef}
        className="modal fixed max-h-[100dvh] overflow-y-auto top-0 lg:top-1/2 lg:left-1/2 transform lg:-translate-x-1/2 lg:-translate-y-1/2 z-[9999] w-full max-w-screen-lg overflow-hidden pt-12 lg:pb-0"
      >
        <button
          onClick={() => closeModal()}
          aria-label="Close Modal"
          title="Close Modal"
          className="close-modal-btn absolute top-4 right-4 overflow-hidden z-10 hover:rotate-180 transition-all duration-300"
        >
          <Close />
        </button>
        <div className="lg:h-auto w-full flex lg:items-center lg:block">{children}</div>
      </div>
    </>
  ) : null
}
