import NextImage from "next/image"
import { useState } from "react"
import cn from "classnames"
import slugify from "slugify"
import Breadcrumbs from "components/Breadcrumbs"
import Image from "components/Image"
import CallToAction from "components/CallToAction"
import richText from "utilities/richText"
import getSbImageDimensions from "utilities/getSbImageDimensions"
import Modal from "components/Modal"
import Arrow from "public/assets/carousel-arrow.svg"
import useModal from "utilities/useModal"

function LookInsideModal({ images, heading }) {
  const lastIndex = images?.length - 1
  const [currentSlide, setSlide] = useState(0)
  const modalProps = useModal(
    `look-inside-${slugify(heading, {
      lower: true,
    })}`,
  )

  return (
    <Modal {...modalProps}>
      <div className="relative w-full overflow-hidden">
        <div className="max-w-full mx-auto pt-4 lg:px-16">
          <div
            className="flex transition-all duration-700 gap-0 w-full"
            style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            aria-live="off"
          >
            {images?.map((image, idx) => {
              const visible = idx === currentSlide
              return (
                <div
                  className={cn("min-w-full", {
                    "opacity-1": visible,
                    "opacity-0": !visible,
                  })}
                  key={idx}
                >
                  <Image
                    src={image?.filename}
                    alt={image?.alt}
                    height={getSbImageDimensions("height", image?.filename)}
                    width={getSbImageDimensions("width", image?.filename)}
                  />
                </div>
              )
            })}
          </div>
        </div>

        {images?.length > 1 ? (
          <div className="w-max mx-auto flex gap-4 lg:gap-0 mt-4">
            <button
              onClick={() => setSlide(currentSlide - 1)}
              disabled={currentSlide === 0}
              className="lg:absolute left-0 lg:bottom-1/2 lg:-translate-y-1/2 group/left-arrow"
              aria-label="Previous Slide"
            >
              <Arrow className="rotate-180 transition-all duration-300 fill-secondary group-disabled/left-arrow:fill-grey-cool-tint" />
            </button>
            <button
              onClick={() => setSlide(currentSlide + 1)}
              disabled={currentSlide === lastIndex}
              className="lg:absolute right-0 lg:bottom-1/2 lg:-translate-y-1/2 group/right-arrow"
              aria-label="Next Slide"
            >
              <Arrow className="transition-all duration-300 fill-secondary group-disabled/right-arrow:fill-grey-cool-tint" />
            </button>
          </div>
        ) : null}
      </div>
    </Modal>
  )
}

export default function BookPageHeader({ blok }) {
  return (
    <header className="lg:px-5 relative overflow-hidden bg-primary">
      {blok?.look_inside_images && blok?.heading ? (
        <LookInsideModal images={blok?.look_inside_images} heading={blok?.heading} />
      ) : null}
      <NextImage
        alt=""
        src="/assets/blue-weave.webp"
        quality={80}
        placeholder="blur"
        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMUUvGoBwABzQD/+RWwWwAAAABJRU5ErkJggg=="
        fill
        sizes="100vw"
        style={{
          objectFit: "cover",
        }}
        priority
        aria-hidden
      />

      <div className="pb-16 lg:pt-16">
        <div className="flex flex-col-reverse lg:flex-row items-stretch gap-8 lg:gap-4 h-full max-w-screen-xl mx-auto">
          <div className="z-10 px-5 lg:px-0 lg:basis-1/2">
            <Breadcrumbs breadcrumbs={blok?.breadcrumbs} />
            <h1 className="text-white py-2 capitalize">{blok.heading}</h1>

            {blok.intro_content && (
              <div className="text-white prose-headings:text-white prose-headings:pb-4">
                {richText(blok.intro_content)}
              </div>
            )}
            <CallToAction href={`#look-inside-${blok.heading}`} className="lg:hidden mt-4">
              Look Inside
            </CallToAction>
          </div>
          {blok?.cover_image?.filename && blok.cover_image.filename !== "" ? (
            <div className="w-full lg:flex flex-col items-center justify-center lg:basis-1/2 gap-8 px-20 sm:px-24 py-6 sm:py-12 bg-highlight-tint z-10">
              <Image
                src={blok?.cover_image?.filename}
                alt={blok?.cover_image?.alt}
                height={getSbImageDimensions("height", blok?.cover_image?.filename)}
                width={getSbImageDimensions("width", blok?.cover_image?.filename)}
                priority
              />
              <CallToAction
                href={`#look-inside-${slugify(blok.heading, {
                  lower: true,
                })}`}
                className="hidden lg:block"
              >
                Look Inside
              </CallToAction>
            </div>
          ) : null}
        </div>
      </div>
    </header>
  )
}
