import SidebarForm from "components/SidebarForm"
import CTABanner from "components/DynamicComponent/molecules/CTABanner"
import CTASidebar from "components/DynamicComponent/molecules/CTASidebar"

export default function ArticleSection({ children, useCTASidebar }) {
  return (
    <>
      <section className="sidebar-form-section lg:px-5">
        <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:items-start lg:gap-16 pt-8 lg:py-16">
          <div className="sidebar-form-section-content prose-h2:text-primary prose-headings:pb-3 px-5 lg:px-0 pb-8 lg:pb-0 w-full">
            {children}
          </div>
          {useCTASidebar ? (
            <CTASidebar
              blok={{ sidebar_heading: "Want Justice? ", sidebar_link: "/contact", sidebar_link_label: "Get Help Now" }}
            />
          ) : (
            <SidebarForm />
          )}
        </div>
      </section>
      <CTABanner />
    </>
  )
}
