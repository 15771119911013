import CallToAction from "components/CallToAction"

export default function CTASidebar({ blok }) {
  return (
    <aside className="bg-primary px-6 py-12 lg:sticky lg:top-8 overflow-clip">
      <div
        className="mx-auto cta-sidebar-brackets form-brackets-primary flex max-w-screen-xl flex-col items-center gap-8"
        data-pagefind-ignore
      >
        <h2 className="text-center text-xl1 text-white">{blok.sidebar_heading}</h2>
        <CallToAction href={blok.sidebar_link}>{blok.sidebar_link_label}</CallToAction>
      </div>
    </aside>
  )
}
