import dynamic from "next/dynamic"
import JotFormEmbed from "components/JotFormEmbed"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function SidebarForm({ children, form_url }) {
  const Content = children?.length ? () => children : dynamic(() => import("../components/DefaultSidebarFormContent"))

  return (
    <aside
      data-pagefind-ignore="all"
      className="sidebar-form bg-primary p-7 @container min-w-[300px] overflow-y-auto lg:sticky lg:top-0"
    >
      <div className="pb-8 text-white prose-h3:pb-4">
        <Content />
      </div>
      <JotFormEmbed
        formURL={form_url ? getStoryblokLink(form_url) : "https://form.jotform.com/233334646041046"}
        initialHeight={860}
      />
    </aside>
  )
}
