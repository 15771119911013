import CallToAction from "components/CallToAction"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function CTABanner({
  blok = {
    heading: "Want Justice?",
    button_link: {
      linktype: "story",
      story: {
        full_slug: "contact",
      },
    },
    button_label: "Get Help Now",
  },
}) {
  return (
    <section className="cta-banner-section bg-primary px-5 py-16 overflow-y-clip" data-pagefind-ignore="all">
      <div className="relative max-w-xl mx-auto flex items-center justify-center">
        <div className="cta-banner form-brackets form-brackets-primary flex flex-col lg:flex-row gap-7 items-center">
          <h2 className="text-white">{blok.heading}</h2>
          <CallToAction href={getStoryblokLink(blok.button_link)}>{blok.button_label}</CallToAction>
        </div>
      </div>
    </section>
  )
}
