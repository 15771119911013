import { getGlobals } from "storyblok/api"
import { getBookPaths, getBook } from "storyblok/books"
import Meta from "components/Meta"
import BookPageHeader from "components/Archive/BookPageHeader"
import StoryblokVisualEditor from "components/StoryblokVisualEditor"
import ArticleSection from "components/Archive/ArticleSection"
import richText from "utilities/richText"
import BookForm from "components/Archive/BookForm"

export default function Book({ bookStory, meta }) {
  return (
    <>
      <Meta info={meta} />
      <StoryblokVisualEditor story={bookStory?.content}>
        <main data-pagefind-body data-pagefind-sort="weight[data-weight]" data-weight="3">
          <BookPageHeader
            blok={{
              ...bookStory?.content,
              heading: bookStory?.content?.name,
              breadcrumbs: [{ text: "Free Injury Law Guides", href: "/books" }, { text: bookStory?.content?.name }],
            }}
          />
          <BookForm jotform_url={bookStory?.content?.jotform_url} form_content={bookStory?.content?.form_content} />
          <ArticleSection useCTASidebar>{richText(bookStory?.content?.content)}</ArticleSection>
        </main>
      </StoryblokVisualEditor>
    </>
  )
}

export async function getStaticProps({ params: { book }, preview = null }) {
  const globals = await getGlobals("exclude-global-sections")
  const bookStory = await getBook(`books/${book}`, preview)

  return {
    props: {
      bookStory: bookStory ?? null,
      meta: bookStory?.content?.twentysixty_seo ?? null,
      globals: globals ?? null,
      preview,
    },
  }
}

export async function getStaticPaths() {
  return {
    paths: await getBookPaths(),
    fallback: false,
  }
}
